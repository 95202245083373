<template>
  <v-row>
    <v-col cols="12">
      <v-container fluid style="max-width: 1600px">
        <v-expansion-panels>
          <v-expansion-panel>
            <v-expansion-panel-header>
              Search
              <span style="padding-left: 2px">({{ totalRecord }})</span>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-row>
                <v-col cols="12" md="3">
                  <v-text-field
                    v-model="searchParam.loadNumber"
                    type="text"
                    label="Load Number"
                    @keydown.enter="searchAllPayment()"
                  />
                </v-col>
                <v-col cols="12" md="3">
                  <v-text-field
                    v-model="searchParam.checkId"
                    type="text"
                    label="Check Id"
                    @keydown.enter="searchAllPayment()"
                  />
                </v-col>
                <v-col cols="12" md="3">
                  <v-text-field
                    v-model="searchParam.name"
                    type="text"
                    label="Name"
                    @keydown.enter="searchAllPayment()"
                  />
                </v-col>
                <v-col cols="12" md="3">
                  <v-select
                    v-model="searchParam.paymentMethod"
                    :items="paymentMethodAll"
                    item-text="name"
                    item-value="value"
                    label="Payment Method"
                    clearable
                  />
                </v-col>
                <v-col cols="12" md="3">
                  <v-select
                    v-model="searchParam.paymentType"
                    :items="paymentTypeAll"
                    item-text="name"
                    item-value="value"
                    label="Payment Type"
                    clearable
                  />
                </v-col>
                <v-col cols="12" md="3">
                  <carrier-auto-complete
                    carrier-text="Carrier"
                    class="pt-3"
                    @event="selectedCarrier"
                  />
                </v-col>
                <v-col cols="12" md="3">
                  <v-text-field
                    v-model="searchParam.invoiceNumber"
                    type="text"
                    label="Invoice Number"
                    @keydown.enter="searchAllPayment()"
                  />
                </v-col>
                <v-col cols="12" md="3">
                  <v-text-field
                    v-model="searchParam.checkNumber"
                    type="text"
                    label="Check Number"
                    @keydown.enter="searchAllPayment()"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="3">
                  <v-btn
                    class="blue white--text dark-3"
                    small
                    @click="searchAllPayment()"
                  >
                    <v-icon small> mdi-search-web </v-icon>
                    Filter
                  </v-btn>
                  <v-btn class="ml-1" small @click="resetSearch()">
                    Reset
                  </v-btn>
                </v-col>
              </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-container>
    </v-col>
    <v-col cols="12" class="pt-0">
      <v-container fluid class="pt-0 data-container">
        <v-btn
          :disabled="approveAllBtn"
          small
          class="font-weight-bold blue darken-1 mb-2"
          @click="confirmMultipleCheckApprove()"
        >
          Approve Selected
        </v-btn>
        <v-data-table
          :headers="headers"
          :items="loadPayments"
          :single-select="false"
          show-select
          item-key="checkId"
          class="elevation-2 data-table"
          calculate-widths
          :loading="loadingIcon"
          fixed-header
          hide-default-footer
          :items-per-page="pageSize"
          :item-class="itemRowBackground"
          @item-selected="selectRecord"
          @toggle-select-all="toggleSelectAll"
        >
          <template v-slot:item.recipient="{ item }">
            <p v-if="item.recipient" class="mb-0">
              <span v-if="item.recipient.line_1">{{
                item.recipient.line_1
              }}</span>
              <span v-else> {{ item.recipient }}</span>
            </p>
            <p v-if="item.recipient.city" class="mb-0">
              <span
                >{{ item.recipient.city }},
                {{ item.recipient.state }}
                {{ item.recipient.zip }}</span
              >
            </p>
          </template>
          <template v-slot:item.carrierName="{ item }">
            <router-link
              :to="{
                name: 'Carrier Detail',
                params: {
                  id: item.carrier.id,
                  title: item.carrier.name + ' - ' + item.carrier.mc_number,
                },
              }"
              class="name-link"
              target="_blank"
            >
              <span style="color: #464646">{{ item.carrierName }}</span>
            </router-link>
          </template>
          <template v-slot:item.payment="{ item }">
            <p class="mb-0">
              <b>{{ item.paymentMethod }} - {{ item.paymentType }}</b>
            </p>
            <p v-if="item.checkId" class="mb-0">
              <b>Id: </b>
              <span>{{ item.checkId }}</span>
            </p>
          </template>
          <template v-slot:item.id="{ item }">
            <span>{{ item.id }}</span>
            <v-edit-dialog
              v-if="item.hasError"
              :return-value.sync="item.errorMessage"
              width="250px"
            >
              <v-icon v-if="item.hasError"> mdi-alert-octagon </v-icon>
              <template v-slot:input>
                <div class="pa-3" style="max-width: 250px">
                  <v-row>
                    <v-col cols="1">
                      <v-icon>mdi-alert-octagon</v-icon>
                    </v-col>
                    <v-col>
                      <h4>Error</h4>
                    </v-col>
                  </v-row>
                  <span v-html="item.errorMessage" />
                </div>
              </template>
            </v-edit-dialog>
          </template>
          <template v-slot:item.loadId="{ item }">
            <v-btn
              text
              small
              target="_blank"
              :to="{
                name: 'Load Detail',
                params: { id: item.loadId },
              }"
            >
              {{ item.loadId }}
            </v-btn>
          </template>
          <template v-slot:item.description="{ item }">
            <v-btn
              small
              class="font-weight-bold red darken-1"
              @click="
                confirmVoidCheck(
                  item.loadId,
                  item.checkId,
                  item.id,
                  item.amount
                )
              "
            >
              Void
            </v-btn>
            <v-btn
              small
              class="font-weight-bold green darken-1 ml-2"
              @click="confirmCheckApprove(item.checkId, item.id, item.amount)"
            >
              Approve
            </v-btn>
          </template>
          <template v-slot:footer>
            <v-page
              :total-row="totalRecord"
              :page-size-menu="[10, 20, 50, 100]"
              class="pt-3 pr-3 pb-3"
              @page-change="readDataFromAPI"
            />
          </template>
        </v-data-table>
      </v-container>
    </v-col>
    <v-dialog v-model="dialog" max-width="290">
      <v-card>
        <v-card-title class="text-h5"> Confirm Payment </v-card-title>
        <v-card-text>
          <p>
            Please confirm check number <b>{{ checkNumber }}</b> in the amount
            of:
          </p>
          <p class="text-center mb-0">
            <b>${{ checkAmount }}</b>
          </p>
        </v-card-text>

        <v-card-actions>
          <v-spacer />
          <v-btn color="grey darken-1" text @click="dialog = false">
            Cancel
          </v-btn>

          <v-btn
            color="orange darken-1"
            text
            @click="processSignleCheckApprove()"
          >
            Confirm
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogMultipleCheck" max-width="290">
      <v-card>
        <v-card-title class="text-h5"> Confirm Checks </v-card-title>
        <v-card-text>
          <p>Please confirm all of the selected checks.</p>
        </v-card-text>

        <v-card-actions>
          <v-spacer />
          <v-btn
            color="grey darken-1"
            text
            @click="dialogMultipleCheck = false"
          >
            Cancel
          </v-btn>

          <v-btn color="orange darken-1" text @click="processCheckApprove()">
            Confirm
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="cancelCheckDialog" max-width="290">
      <v-card>
        <v-card-title class="text-h5"> Void Check </v-card-title>
        <v-card-text>
          <p>
            Please confirm that you would like to void check
            {{ checkNumber }}.
          </p>
        </v-card-text>

        <v-card-actions>
          <v-spacer />
          <v-btn color="grey darken-1" text @click="cancelCheckDialog = false">
            Cancel
          </v-btn>

          <v-btn color="orange darken-1" text @click="voidCheck()">
            Confirm
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar
      v-model="snackbar"
      :multi-line="true"
      absolute
      right
      bottom
      color="success"
      transition="scroll-x-reverse-transition"
    >
      <span v-html="snackbarText" />

      <template v-slot:action="{ attrs }">
        <v-btn color="black" text v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </v-row>
</template>
<script>
import { mapGetters } from "vuex"
import CarrierAutoComplete from "@/views/pages/components/CarrierAutoComplete"
export default {
  name: "LoadPayment",
  components: {
    CarrierAutoComplete,
  },
  data() {
    return {
      dialog: false,
      cancelCheckDialog: false,
      snackbar: false,
      snackbarText: null,
      dialogMultipleCheck: false,
      totalRecord: 0,
      searchParam: {
        loadNumber: null,
        checkId: null,
        carrierId: null,
        name: null,
        paymentType: null,
        invoiceNumber: null,
        checkNumber: null,
      },
      loadPayments: [],
      paymentStatusAll: [
        {
          id: 1,
          name: "Paid",
        },
        {
          id: 2,
          name: "In Process",
        },
        {
          id: 3,
          name: "Unpaid",
        },
        {
          id: 4,
          name: "Void",
        },
        {
          id: 5,
          name: "Expired",
        },
        {
          id: 6,
          name: "Printed",
        },
        {
          id: 7,
          name: "Mailed",
        },
        {
          id: 8,
          name: "Failed",
        },
        {
          id: 9,
          name: "Refunded",
        },
      ],
      paymentMethodAll: [
        {
          value: "ACH",
          name: "ACH",
        },
        {
          value: "CHECK",
          name: "Check",
        },
        {
          value: "FACTORING",
          name: "Factoring",
        },
      ],
      paymentTypeAll: [
        {
          value: "QUICK_PAY",
          name: "Quick Pay",
        },
        {
          value: "STANDARD_PAY",
          name: "Net 30",
        },
      ],
      headers: [
        {
          text: "Paid On",
          align: "center",
          sortable: false,
          value: "createdAt",
        },
        {
          text: "Load #",
          align: "center",
          sortable: false,
          value: "loadId",
        },
        {
          text: "Carrier",
          align: "center",
          sortable: false,
          value: "carrierName",
        },
        {
          text: "Name",
          align: "center",
          sortable: false,
          value: "name",
        },
        {
          text: "Recipient",
          align: "center",
          sortable: false,
          value: "recipient",
        },
        {
          text: "Amount",
          align: "right",
          sortable: false,
          value: "amount",
        },
        {
          text: "Status",
          align: "center",
          sortable: false,
          value: "statusDisplay",
        },
        {
          text: "Payment",
          align: "center",
          sortable: false,
          value: "payment",
        },
        {
          text: "Invoice #",
          align: "center",
          sortable: false,
          value: "invoiceNumber",
        },
        {
          text: "Check #",
          align: "center",
          sortable: false,
          value: "id",
        },
        {
          text: "",
          align: "center",
          sortable: false,
          value: "description",
        },
      ],
      queryParams: {},
      pageSize: 10,
      numberOfPages: 0,
      page: 1,
      checkId: null,
      checkNumber: null,
      checkAmount: null,
      approveCheckIds: [],
      approveAllBtn: true,
      approvedItems: [],
      cancelCheck: null,
    }
  },
  computed: {
    ...mapGetters("generic", {
      loadingIcon: "loadingIcon",
    }),
    ...mapGetters("billing", {
      loadPaymentList: "loadPaymentList",
    }),
  },
  watch: {
    loadPaymentList() {
      this.totalRecord = this.loadPaymentList.count
      this.loadPayments = this.loadPaymentList.results
      this.numberOfPages = Math.ceil(this.totalRecord / this.pageSize)
      if (this.approvedItems.length > 0) {
        this.snackbarText = ""
        for (let i = 0; i < this.approvedItems.length; i++) {
          const idx = this.loadPayments.findIndex(
            (x) => x.checkId === this.approvedItems[i].check_id
          )
          if (this.approvedItems[i].is_success === false) {
            this.loadPayments[idx].hasError = true
            this.loadPayments[idx].errorMessage = this.approvedItems[i].message
          } else {
            this.snackbar = true
            this.snackbarText += `${this.approvedItems[i].message}<br/>`
          }
        }
        this.approvedItems = []
      }
    },
  },
  mounted() {
    this.loadPayments = []
    this.getLoadPayments()
  },
  methods: {
    getLoadPayments() {
      this.queryParams = {}
      this.queryParams.limit = 10
      this.queryParams.offset = 0
      this.$store.dispatch("billing/GET_LOAD_PAYMENTS", this.queryParams)
    },
    resetSearch() {
      this.queryParams = {}
      this.queryParams.limit = 10
      this.queryParams.offset = 0
      this.searchParam.loadNumber = null
      this.searchParam.checkId = null
      this.searchParam.carrierId = null
      this.searchParam.name = null
      this.searchParam.paymentMethod = null
      this.searchParam.paymentType = null
      this.searchParam.invoiceNumber = null
      this.searchParam.checkNumber = null
      this.$store.commit("generic/setCarrierId", null)
      this.$store.dispatch("billing/GET_LOAD_PAYMENTS", this.queryParams)
    },
    searchAllPayment() {
      this.queryParams = {}
      this.queryParams.limit = 10
      this.queryParams.offset = 0
      if (this.searchParam.loadNumber) {
        this.queryParams.load_id = this.searchParam.loadNumber
      }
      if (this.searchParam.checkId) {
        this.queryParams.check_id__icontains = this.searchParam.checkId
      }
      if (this.searchParam.carrierId) {
        this.queryParams.carrier_id = this.searchParam.carrierId
      }
      if (this.searchParam.name) {
        this.queryParams.name__icontains = this.searchParam.name
      }
      if (this.searchParam.paymentType) {
        this.queryParams.payment_type = this.searchParam.paymentType
      }
      if (this.searchParam.paymentMethod) {
        this.queryParams.payment_method = this.searchParam.paymentMethod
      }
      if (this.searchParam.invoiceNumber) {
        this.queryParams.invoice_number__icontains =
          this.searchParam.invoiceNumber
      }
      if (this.searchParam.checkNumber) {
        this.queryParams.id = this.searchParam.checkNumber
      }
      this.$store.dispatch("billing/GET_LOAD_PAYMENTS", this.queryParams)
    },
    readDataFromAPI(value) {
      if (this.loadPayments.length > 0) {
        this.page = value.pageNumber
        this.pageSize = value.pageSize
        let pageNumber = this.pageSize * this.page - this.pageSize
        if (pageNumber < 0) {
          pageNumber = 0
        }
        this.queryParams.offset = pageNumber
        this.queryParams.limit = this.pageSize
        this.$store.dispatch("billing/GET_LOAD_PAYMENTS", this.queryParams)
      }
    },
    selectedCarrier(value) {
      if (value.carrier) {
        if (value.flag === 1) {
          this.searchParam.carrierId = value.carrier.id
          this.searchAllPayment()
        } else {
          this.searchParam.carrierId = value.carrier.id
        }
      }
    },
    confirmCheckApprove(checkId, checkNumber, checkAmount) {
      this.checkId = checkId
      this.checkNumber = checkNumber
      this.checkAmount = checkAmount
      this.dialog = true
    },
    confirmMultipleCheckApprove() {
      this.dialogMultipleCheck = true
    },
    processCheckApprove() {
      this.$store
        .dispatch("billing/POST_PAYMENT_APPROVE", this.approveCheckIds)
        .then((data) => {
          this.dialogMultipleCheck = false
          this.approveCheckIds = []
          this.approvedItems = data
          this.$store.dispatch("billing/GET_LOAD_PAYMENTS", this.queryParams)
        })
        .catch((e) => {
          this.dialogMultipleCheck = false
        })
    },
    processSignleCheckApprove() {
      const checkIds = []
      checkIds.push(this.checkId)
      this.$store
        .dispatch("billing/POST_PAYMENT_APPROVE", checkIds)
        .then((data) => {
          this.dialog = false
          this.checkId = null
          this.checkAmount = null
          this.checkNumber = null
          this.approvedItems = data
          this.$store.dispatch("billing/GET_LOAD_PAYMENTS", this.queryParams)
        })
        .catch((e) => {
          this.dialog = false
        })
    },
    selectRecord(value) {
      if (value.value === true) {
        this.approveCheckIds.push(value.item.checkId)
      } else {
        this.approveCheckIds.splice(
          this.approveCheckIds.indexOf(value.item.checkId),
          1
        )
      }
      this.approveAllBtn = !(this.approveCheckIds.length > 0)
    },
    toggleSelectAll(values) {
      const checked = values.value
      if (values !== null) {
        const items = values.items
        if (checked === true) {
          for (let i = 0; i < items.length; i++) {
            this.approveCheckIds.push(items[i].checkId)
          }
        } else {
          for (let i = 0; i < items.length; i++) {
            this.approveCheckIds.splice(
              this.approveCheckIds.indexOf(items[i].checkId),
              1
            )
          }
        }
      }
      this.approveAllBtn = !checked
    },
    itemRowBackground: function (item) {
      return item.hasError ? "high-light-row" : ""
    },
    confirmVoidCheck(loadId, checkId, checkNumber, amount) {
      this.cancelCheck = {
        load_id: loadId,
        check_id: checkId,
      }
      this.checkNumber = checkNumber
      this.checkAmount = amount
      this.cancelCheckDialog = true
    },
    voidCheck() {
      this.$store
        .dispatch("billing/POST_CHECK_VOID", this.cancelCheck)
        .then(() => {
          this.$store.dispatch("billing/GET_LOAD_PAYMENTS", this.queryParams)
          this.cancelCheckDialog = false
        })
        .catch((e) => {
          this.cancelCheckDialog = false
        })
    },
  },
}
</script>
<style scoped>
.v-data-table /deep/ .sticky-header {
  position: sticky;
  top: var(--toolbarHeight);
}
.v-data-table /deep/ .v-data-table__wrapper {
  overflow: unset;
}
.v-data-table /deep/ .v-data-table__wrapper /deep/ .v-data-table-header th {
  background-color: #f6f6f6;
}
.v-data-table /deep/ .v-data-table__wrapper /deep/ .mdi-close-box {
  color: #ffa500 !important;
}
.name-link {
  text-decoration: none;
  cursor: pointer;
  color: #000000;
}
.name-link:hover {
  text-decoration: none;
}
</style>
<style>
select {
  -webkit-appearance: auto;
  -moz-appearance: auto;
}
.v-pagination {
  border-top: thin solid rgba(0, 0, 0, 0.12);
}
.high-light-row {
  background-color: #eb8886 !important;
}
</style>
